import CurrencyInputField from 'components/CurrencyInputField'
import FormField from 'components/FormField'
import { FormikProps, FormikValues } from 'formik'
import { manualPaymentOptions } from 'hooks/manualPayments'
import { Form, Grid, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'

type ManualPaymentFormProps = {
  currency: string
  invoiceId: number
} & FormikProps<FormikValues>

const ManualPaymentForm = ({
  currency,
  invoiceId,
  errors,
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={isSubmitting}>
        <Grid.Row className="mb-4">
          <Grid.Col width={6}>
            <Form.Group className="mb-0" label="" />
            <Text>
              <span className="font-weight-bold">Invoice: </span>
              {invoiceId}
            </Text>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <Form.Group label="Payment Type">
              <Form.Select name="manual_payment" onChange={handleChange}>
                {manualPaymentOptions(currency).map((paymentType) => (
                  <option value={paymentType.value} key={paymentType.value}>
                    {paymentType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col sm={6} lg={3}>
            <Form.Group label="Amount">
              <Form.InputGroup>
                <Form.InputGroupPrepend>
                  <Form.InputGroupText>$</Form.InputGroupText>
                </Form.InputGroupPrepend>
                <CurrencyInputField
                  name="amount"
                  value={values.amount}
                  setFieldValue={setFieldValue}
                  prefix=""
                  customInput={undefined}
                  className="form-control"
                />
              </Form.InputGroup>
              <span className="field-error text-danger">
                {errors.total && errors.total}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="mb-4">
          <Grid.Col>
            <Form.Group label="Email">
              <FormField
                name="description"
                onChange={handleChange}
                placeholder="Email"
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <button
          className="btn btn-primary btn-block"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? 'Processing...'
            : `Receive Payment of ${formatMoney(values.amount, currency)}`}
        </button>
      </fieldset>
    </form>
  )
}

export default ManualPaymentForm
