import { Formik, FormikValues } from 'formik'
import { useRef } from 'react'
import { Button, Card, Form, List, Text } from 'tabler-react'
import * as Yup from 'yup'

import { HOSTNAMES } from 'components/constants'
import FormField from 'components/FormField'
import { useRootStore } from '../../../hooks'

const LoginPostForm = () => {
  const { redirectUrl } = useRootStore()
  const formEl = useRef(null)
  const doHtmlFormPost = (values: any) => {
    const current: any = formEl.current
    current.submit()
  }

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required.'),
    password: Yup.string().required('This field is required.')
  })
  const envHost = HOSTNAMES.find((host) => host?.host === window.location.host)

  const renderForm = ({ handleSubmit, isSubmitting }: FormikValues) => {
    return (
      <>
        <form
          className="login-form "
          method="POST"
          action="/auth/login"
          ref={formEl}
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        >
          <Card>
            <Card.Body>
              <FormField
                appendleft={
                  <Button
                    icon={'mail'}
                    color={'secondary text-muted'}
                    disabled
                  />
                }
                name="email"
                label=""
                placeholder="Email"
                type="text"
              />
              <FormField
                appendleft={
                  <Button
                    icon={'lock'}
                    color={'secondary text-muted'}
                    disabled
                  />
                }
                name="password"
                type="password"
                label=""
                placeholder="Password"
                //  appendright={<Button icon={'eye'} color={'white text-muted'} disabled />}
              />
              <FormField name="redirect" type="hidden" value={redirectUrl} />
              <Form.Footer className="mb-3">
                <Button
                  block
                  type="submit"
                  color="primary"
                  className={`${isSubmitting && 'btn-loading'}`}
                  disabled={isSubmitting}
                >
                  LOGIN
                </Button>
              </Form.Footer>
              <Text className="text-center text-muted">
                <Text.Small>
                  <a href="/reset-password" className="">
                    Reset Password
                  </a>
                </Text.Small>
              </Text>
            </Card.Body>
          </Card>
        </form>
        {/* Temporarily only allow signups on the SKYCRU domain, COMBAT and other domains will be added later */}
        {envHost?.name === 'SKYCRU' && (
        <Text className="text-center text-muted">
          Don't have an account yet?{' '}
          <a href="/signup" className="">
            SIGNUP
          </a>
        </Text>
        )}
        <Text.Small className="text-center">
        {envHost?.name === 'COMBAT' ? (
          <List inline className="list-inline-dots mb-0">
            <List.Item inline>
              <a href="https://combatalliance.org/contact" className="text-info" target="_blank">
                NEED HELP?
              </a>
            </List.Item>
            <List.Item inline>
              <a href="https://combatalliance.org/privacy" className="text-info" target="_blank">
                PRIVACY
              </a>
            </List.Item>
          </List>
        ) : (
          <List inline className="list-inline-dots mb-0">
            <List.Item inline>
              <a href="/help" className="text-info">
                NEED HELP?
              </a>
            </List.Item>
            <List.Item inline>
              <a href="https://skycru.com/privacy" className="text-info" target="_blank">
                PRIVACY
              </a>
            </List.Item>
          </List>
        )}
        </Text.Small>
        {/* Display Special Account links only for skycru domains. skycru.com, skycruapp.com */}
        {envHost?.name === 'SKYCRU' && (
          <>
            <Text className="d-block text-center text-muted mt-5">
              Request a special account:
            </Text>
            <div className="text-center">
              <a
                href="https://skycru.com/coaching"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3 text-info"
              >
                Coaches
              </a>
              <a
                href="https://skycru.com/dzm"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3 text-info"
              >
                Dropzones
              </a>
              <a
                href="https://skycru.com/event_organizing"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3 text-info"
              >
                Event Organizers
              </a>
              <br />
              <a
                href="https://skycru.com/orgs"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3 text-info"
              >
                Organizations
              </a>
              {/* save for later
                <a href='https://skycru.com/packer' rel='noopener noreferrer' target='_blank' className='mr-3 text-info'>
                  Packers
                </a>
              */}
              <a
                href="https://skycru.com/rigging"
                rel="noopener noreferrer"
                target="_blank"
                className="text-info"
              >
                Riggers & Lofts
              </a>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        redirect: redirectUrl
      }}
      validationSchema={loginValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        doHtmlFormPost(values)
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default LoginPostForm
