import { manualPaymentOpts } from 'components/constants'

export const manualPaymentOptions = (currency) => {
  //const options = manualPaymentOpts

  return manualPaymentOpts.filter((option) => {
    if (currency !== 'USD' && option.value === 'ACH') {
      return false
    }
    if (currency !== 'USD' && option.value === 'VENMO') {
      return false
    }
    if (currency !== 'USD' && option.value === 'ZELLE') {
      return false
    }
    if (currency !== 'CAD' && option.value === 'E-TRANSFER') {
      return false
    }
    if (currency !== 'EUR' && option.value === 'iDEAL') {
      return false
    }
    if (currency !== 'GBP' && option.value === 'BACS') {
      return false
    }

    return true
  })
}
