import { useMutation } from '@apollo/client'
import Modal from 'components/Modal'
import TextEditor from 'components/text-editor/TextEditor'
import { useFormik } from 'formik'
import { SEND_BULK_REGISTRATION_EMAIL } from 'graphql/SEND_BULK_REGISTRATION_EMAIL'
import { useRootStore } from 'hooks'
import React from 'react'
import { toast } from 'react-toastify'
import { Button, Form, Grid } from 'tabler-react'

type BulkEmailModalProps = {
  isOpen: boolean
  toggle: () => void
  students: any[]
  teamId: number
  campId: number
}

const BulkEmailModal: React.FC<BulkEmailModalProps> = ({
  isOpen,
  toggle,
  students,
  teamId,
  campId
}) => {
  const [sendBulkRegistrationEmails] = useMutation(
    SEND_BULK_REGISTRATION_EMAIL,
    {
      onCompleted: () => {
        toast.success('Emails sent successfully')
      }
    }
  )
  const { currentUser } = useRootStore()
  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
      recipients: students.map((student) => student.email),
      teamId: teamId,
      campId: campId,
      coachId: currentUser.id
    },
    onSubmit: async (values, { setSubmitting }) => {
      await sendBulkRegistrationEmails({
        variables: values
      })
      setSubmitting(false)
      toggle()
    }
  })

  return (
    <Modal
      title={`Email Blast: ${students.length} selected`}
      open={isOpen}
      content={
        <div className="bulk-email-modal">
          <form onSubmit={formik.handleSubmit}>
            <fieldset disabled={formik.isSubmitting}>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Subject*">
                    <Form.Input
                      name="subject"
                      type="text"
                      autoFocus
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Message*">
                    <TextEditor
                      value={formik.values.message}
                      onChange={(value) =>
                        formik.setFieldValue('message', value)
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button.List align="right">
                <Button icon="send" color="primary" onClick={formik.handleSubmit}>SEND EMAIL</Button>
              </Button.List>
            </fieldset>
          </form>
        </div>
      }
      onClose={toggle}
    />
  )
}

export default BulkEmailModal
