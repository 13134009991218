import { useMutation } from "@apollo/client";
import UpdateCoach from "components/Coaches/Forms/UpdateCoach";
import { TOGGLE_DISABLE_COACH } from "graphql/TOGGLE_DISABLE_COACH";
import { observer } from "mobx-react";
import { clone } from "mobx-state-tree";
import useResetPasswordByEmail from "modules/common/hooks/useResetPasswordByEmail";
import React, { useState } from "react";
import { Avatar, Button, Dropdown, Icon, Table, Tag, Text } from "tabler-react";
import { formatPhoneNumberWithCountryCode } from "utils/phoneFormat";

interface ICoachListItemProps {
  coach: any;
}

const CoachListItem = ({ coach }: ICoachListItemProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [coachClone, setCoachClone] = useState(clone(coach));

  const [toggleDisableCoach] = useMutation(TOGGLE_DISABLE_COACH);
  const { resetPasswordByEmail } = useResetPasswordByEmail();

  const onToggleEdit = () => {
    setIsEditing(true);
    setCoachClone(clone(coach));
  };

  const handleToggleDisableCoach = async (coach_id: number) => {
    await toggleDisableCoach({
      variables: {
        coach_id,
      },
      update(_, { data: { toggleDisableCoach } }) {
        if (toggleDisableCoach) {
          coach.set(
            "is_disabled_on",
            coach.is_disabled_on ? null : new Date().toLocaleString(),
          );
        }
      },
    });
  };

  const confirmSendActivationEmail = (email) => {
    if (window.confirm("Are you sure you want to send activation email?")) {
      resetPasswordByEmail({
        variables: {
          email,
        },
      });
    }
  };

  const countryCode: string = coach?.phone_country_code
    ? `${coach.phone_country_code}`
    : "";
  const phoneNumber: number = coach?.phone_number ?? "";

  const formattedNumber: string =
    formatPhoneNumberWithCountryCode(phoneNumber.toString(), countryCode) ?? "";

  return isEditing ? (
    <UpdateCoach
      setIsEditing={setIsEditing}
      coachClone={coachClone}
      coach={coach}
    />
  ) : (
    <>
      <Table.Col>
        <Avatar
          size="md"
          className="float-left cursor-pointer mr-2"
          status={coach.registration_id ? 'success' : 'secondary'}
          imageURL={
            (coach.profile_avatar &&
              `/api/s3/uploads/${coach.profile_avatar}`) ||
            "//www.gravatar.com/avatar?d=mp"
          }
        />
        {coach.first_name} {coach.last_name}
        <Text.Small className="d-block text-muted">
          <span className="mr-1">SKY.D: {coach.id} </span>
          <Tag color="danger">{coach.is_disabled_on &&
            'Inactive: ' + new Date(coach.is_disabled_on).toLocaleDateString()}</Tag>
        </Text.Small>
      </Table.Col>
      <Table.Col>
        {coach.email ? (
          <a href={"mailto:" + coach.email}>
            <Icon
              link
              name="mail"
              className="float-left m-1 text-primary"
              onClick={(e) => {
                window.location.href = "mailto:" + coach.email;
                e.preventDefault();
              }}
            />
            <span className="d-none d-lg-block">{coach.email}</span>
          </a>
        ) : (
          <Tag color="secondary">No Access</Tag>
        )}
      </Table.Col>
      <Table.Col>
        {coach.phone_number && (
          <a href={`tel:${formattedNumber}`}>
            <Icon
              link
              name="phone"
              className="float-left m-1 text-primary"
              onClick={(e) => {
                window.location.href = `tel:${formattedNumber}`;
                e.preventDefault();
              }}
            />
            <span className="d-none d-lg-block">{formattedNumber}</span>
          </a>
        )}
      </Table.Col>
      <Table.Col>
        <React.Fragment>
          <Button.Dropdown
            icon="more-vertical"
            color="white"
            size="sm"
            position="bottom-end"
            arrow={true}
            arrowPosition="right"
          >
            <Dropdown.Item
              icon="edit"
              onClick={onToggleEdit}
              className="cursor-pointer"
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              icon="lock"
              onClick={(e) => {
                e.preventDefault();
                confirmSendActivationEmail(coach.email);
              }}
              className="cursor-pointer"
            >
              Reset Password
            </Dropdown.Item>
            <Dropdown.ItemDivider />
            <Dropdown.Item
              className={
                !coach.is_disabled_on
                  ? "text-danger cursor-pointer"
                  : "text-success cursor-pointer"
              }
              icon={
                !coach.is_disabled_on
                  ? "x-circle text-danger"
                  : "check-circle text-success"
              }
              onClick={() => handleToggleDisableCoach(coach.id)}
            >
              {!coach.is_disabled_on ? "Disable" : "Enable"}
            </Dropdown.Item>
          </Button.Dropdown>
        </React.Fragment>
      </Table.Col>
    </>
  );
};

export default observer(CoachListItem);
