import { IconBrandX } from '@tabler/icons-react'
import { HOSTNAMES } from 'components/constants'
import { Container, Grid, Icon, List, Tag, Text } from 'tabler-react'

const FooterBottom = () => {
  const date = new Date()
  const year = date.getFullYear()

  const envHost = HOSTNAMES.find((host) => host?.host === window.location.host)

  return (
    <>
      <footer className="footer">
        <Container>
          <Grid.Row className="align-items-center">
            <Grid.Col>
              <List inline className="list-inline-dots mb-0">
                <List.Item inline>
                  © {year + ' '}
                  <a
                    href={'https://' + envHost?.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {envHost?.siteName ?? 'SKYCRU'}
                  </a>
                </List.Item>
                {envHost?.name === 'COMBAT' ? (
                  <>
                    <List.Item inline>
                      <a href="https://combatalliance.org/contact" className="text-muted" target="_blank">
                        HELP
                      </a>
                    </List.Item>
                    <List.Item inline>
                      <a href="https://combatalliance.org/privacy" className="text-muted" target="_blank">
                        PRIVACY
                      </a>
                    </List.Item>
                  </>
                ) : (
                  <>
                    <List.Item inline>
                      <a href="/help" className="text-muted" target="_blank">
                        HELP
                      </a>
                    </List.Item>
                    <List.Item inline>
                      <a href="https://skycru.com/privacy" className="text-muted" target="_blank">
                        PRIVACY
                      </a>
                    </List.Item>
                  </>
                )}
              </List>
              {envHost?.name !== 'COMBAT' && (
                <>
                  <a
                    href="https://www.facebook.com/skycru/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="facebook" className="btn btn-md" />
                  </a>
                  <a
                    href="https://x.com/skycru/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fe btn btn-md">
                      <IconBrandX size={14} />
                    </i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/skycru"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="linkedin" className="btn btn-md" />
                  </a>
                  {/*  HIDE TEMPORARILY
                  <a
                    href="https://www.instagram.com/fly.skycru/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="instagram" className="btn btn-md" />
                  </a
                  */}
                </>
              )}
            </Grid.Col>
            <Grid.Col auto={true} className="ml-auto">
              <Grid.Row className="align-items-center">
                <Grid.Col className="col-auto">
                  <List inline className="list-inline-dots mb-0">
                    <List.Item inline>
                      {envHost?.siteTag &&
                        <Tag
                          color="primary"
                          size="sm"
                        >
                          {envHost?.siteTag}
                        </Tag>
                      }
                      <Tag
                        color="white"
                        className={'text-danger'}
                        size="sm"
                      >
                        {envHost?.env}
                      </Tag>
                    </List.Item>
                    <List.Item inline>
                      <Text.Small className="text-muted">v1.31.111</Text.Small>
                    </List.Item>
                  </List>
                </Grid.Col>
                {/*
                <Grid.Col>
                  <a
                    className="btn btn-sm btn-outline-primary"
                    href="https://apps.apple.com/us/app/skycru/id1292785676"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon prefix="fa" name="apple" aria-hidden="true" /> iOS
                  </a>
                </Grid.Col>
                <Grid.Col className="col-auto">
                  <a
                    className="btn btn-sm btn-outline-primary"
                    href="https://play.google.com/store/apps/details?id=com.skycru.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon prefix="fa" name="android" aria-hidden="true" /> Android
                  </a>
                </Grid.Col>
                */}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </footer>
    </>
  )
}

export default FooterBottom
