import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Icon } from 'tabler-react';
import { Types } from '../../types/graphql'

import RecentStudentListItem from './RecentStudentListItem';

interface IRecentStudentListProps {
  recentStudents: Types.Student[];
}

const RecentStudentList = ({ recentStudents }: IRecentStudentListProps) => {
  return (
    <Grid.Col md={6} lg={4} xs={12} className="grid-col mb-0">
      <Card statusColor="primary">
        <Card.Header>
          <Card.Title>
            <Icon name="users" className="mr-2 ml-0 text-blue" />
            <Link to="/admin/user-list">Users (students)</Link>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <ul className="list-unstyled list-separated">
            {recentStudents.map((student: Types.Student) => (
              <RecentStudentListItem student={student} key={student.id} />
            ))}
          </ul>
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default RecentStudentList;
