import { Link } from 'react-router-dom';
import { Card, Grid, Icon } from 'tabler-react';

import RecentStudentRegistrationsListItem from './RecentStudentRegistrationsListItem';
import { Types } from '../../types/graphql'

interface IRecentStudentRegistrationsListProps {
  recentRegisteredStudents: Types.RegisteredStudents[];
}

const RecentStudentRegistrationsList = ({
  recentRegisteredStudents
}: IRecentStudentRegistrationsListProps) => {
  return (
    <Grid.Col md={6} lg={4} xs={12} className="grid-col mb-0">
      <Card statusColor="orange">
        <Card.Header>
          <Card.Title>
            <Icon name="calendar" className="mr-2 ml-0 text-orange" />
            <Link to="/admin/user-registrations">Registrations</Link>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <ul className="list-unstyled list-separated">
            {recentRegisteredStudents.map(
              (student: Types.RegisteredStudents) => {
                return (
                <RecentStudentRegistrationsListItem
                  student={student}
                  key={student.user_registrations_id}
                />
              )}
            )}
          </ul>
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default RecentStudentRegistrationsList;
