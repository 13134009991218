import gql from 'graphql-tag'

export const SEND_BULK_REGISTRATION_EMAIL = gql`
  mutation SEND_BULK_REGISTRATION_EMAIL(
    $recipients: [String!]!
    $campId: ID!
    $message: String!
    $subject: String!
    $teamId: ID!
    $coachId: ID!
  ) {
    sendBulkRegistrationEmails(
      recipients: $recipients
      campId: $campId
      message: $message
      subject: $subject
      teamId: $teamId
      coachId: $coachId
    )
  }
`
