import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import CalendarLink from 'components/Calendar/CalendarLink'
import EventRegistrationListItem from 'components/Event/EventRegistrationListItem'
import { CAMP_HAS_JUMP_TICKETS } from 'graphql/CAMP_HAS_JUMP_TICKETS'
import { GET_CAMP_BY_ID } from 'graphql/GET_CAMP_BY_ID'
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from 'graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { CAMP_HAS_JUMP_TICKETS as CAMP_HAS_JUMP_TICKETS_TYPE } from 'graphql/types/CAMP_HAS_JUMP_TICKETS'
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from 'graphql/types/GET_CAMP_BY_ID'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import AddJumpTicketsButton from 'modules/registration/options/components/buttons/AddJumpTicketsButton'
import ResetJumpTicketsButton from 'modules/registration/options/components/buttons/ResetJumpTicketsButton'
import TShirtDetailsButton from 'modules/registration/options/components/buttons/TShirtDetailsButton'
import SendEmailToEventRegisteredUsersButton from 'modules/user/registrations/components/buttons/SendEmailToEventRegisteredUsersButton'
import EventRegistrationTotalConfirmed from 'modules/user/registrations/components/stamps/EventRegistrationTotalConfirmed'
// import EventRegistrationTotalPayments from 'modules/user/registrations/components/stamps/EventRegistrationTotalPayments'
// import EventRegistrationTotalPendingRevenue from 'modules/user/registrations/components/stamps/EventRegistrationTotalPendingRevenue'
import CompetitionGroupsList from 'components/Calendar/CompetitionGroupList'
import EventGroupsList from 'components/Calendar/EventGroupsList'
import { EEventTypes } from 'components/enum'
import { Loading } from 'components/Loading'
import EventController from 'modules/camp/components/EventController'
import EventFinance from 'modules/camp/components/EventFinance'
import useGetRegistrationStatus from 'modules/team-registration-status/hooks/useGetRegistrationStatus'
import EventRegistrationTotalPayments from 'modules/user/registrations/components/stamps/EventRegistrationTotalPayments'
import EventRegistrationTotalPendingRevenue from 'modules/user/registrations/components/stamps/EventRegistrationTotalPendingRevenue'
import useGetRegisteredStudents from 'modules/user/registrations/hooks/useGetRegisteredStudents'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'
import { Button, Card, Dimmer, Dropdown, Form, Grid, Table } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { getWingLoad } from 'utils/numberFormat'
import { useRootStore } from '../../hooks'
import useGetEventOrganizers from '../../modules/user/roles/hooks/useGetEventOrganizers'
import PageNoAccess from '../../pages/PageNoAccess'
import { Types } from '../../types/graphql'
import BulkEmailModal from './Modals/BulkEmailModal'
import { BulkLogPaymentModal } from './Modals/BulkPaymentLogForm'
import { BulkStatusChangeModal } from './Modals/BulkStatusModalForm'

const EventRegistrationListSkeleton = () => {
  return (
    <Box>
      <Box pb={2}>
        <Skeleton height={65} width="100%" />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box pb={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
      <Box py={2}>
        <Skeleton height={65} />
      </Box>
    </Box>
  )
}

const EventRegistrationList = () => {
  const { history } = useReactRouter()
  const { currentCoachTeam } = useRootStore()
  const { campId } = useParams<{ campId: string }>()
  const { isAdmin, isCoachSuperAdmin, isOrg } = useGetCurrentUserType()
  const [selectedParticipant, setSelectedParticipant] = useState<
    Types.RegisteredStudents[]
  >([])
  const [isBulkStatusChangeModalOpen, setIsBulkStatusChangeModalOpen] =
    useState<boolean>(false)
  const [isBulkLogPaymentModalOpen, setIsBulkLogPaymentModalOpen] =
    useState<boolean>(false)
  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] =
    useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<string>('')

  const [report, setReport] = useState<string>('list')

  const { data: campData, loading: campLoading } =
    useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
      variables: {
        campId: Number(campId)
      },
      fetchPolicy: 'network-only'
    })

  const { data: campJumpTickets } = useQuery<CAMP_HAS_JUMP_TICKETS_TYPE>(
    CAMP_HAS_JUMP_TICKETS,
    {
      variables: {
        campId: Number(campId)
      }
    }
  )

  const campHasJumpTickets = useMemo(() => {
    return campJumpTickets?.campHasJumpTickets
  }, [campJumpTickets?.campHasJumpTickets])

  const { data, loading } = useGetRegisteredStudents({ campId })

  const { data: campRequirementsData, loading: campRequirementsLoading } =
    useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
      variables: {
        team_id: Number(campData?.getCampById.team_id),
        camp_id: Number(campId)
      },
      skip: !campData?.getCampById.team_id
    })

  const campRequirements = useMemo(() => {
    if (campRequirementsLoading) return []

    return campRequirementsData?.campRegistrationRequirements
      .filter((r) => r.is_exist)
      .map((r) => r.requirement_type_slug)
  }, [campRequirementsData, campRequirementsLoading])

  const isShirtRequired = useMemo(() => {
    if (campRequirementsLoading) return false

    return !!campRequirementsData?.campRegistrationRequirements.find(
      (r) => r.is_exist && r.requirement_type_slug === 'shirt_size'
    )
  }, [campRequirementsData, campRequirementsLoading])

  const { teamStatusOptions } = useGetRegistrationStatus(
    Number(campData?.getCampById.team_id)
  )

  const participantCountByStatus = useMemo(() => {
    if (data?.getRegisteredStudents && teamStatusOptions) {
      return teamStatusOptions.map((status) => {
        const count = data.getRegisteredStudents.filter(
          (student) => student.status === status.name
        ).length
        return { ...status, count }
      })
    }
    return []
  }, [data, teamStatusOptions])

  const confirmedStatus = teamStatusOptions.find(
    (status) => status.type === 'Confirmed' || status.name === 'Confirmed'
  )
  const pendingStatus = teamStatusOptions.find(
    (status) => status.type === 'Pending' || status.name === 'Pending'
  )

  const waitlistStatus = teamStatusOptions.find(
    (status) => status.type === 'Waitlist' || status.name === 'Waitlist'
  )

  const registeredStudents = useMemo(() => {
    if (!loading && data) {
      switch (statusFilter) {
        case 'confirmed':
          return data?.getRegisteredStudents.filter(
            (student) =>
              student.status === confirmedStatus.name ||
              student.status === pendingStatus.name
          )
        case 'waitlist':
          return data?.getRegisteredStudents.filter(
            (student) => student.status === waitlistStatus.name
          )
        case 'rejected':
          return data?.getRegisteredStudents.filter(
            (student) =>
              student.status === 'Rejected' || student.status === 'Withdrawn'
          )
        case 'others':
          return data?.getRegisteredStudents.filter(
            (student) =>
              ![
                'Rejected',
                'Withdrawn',
                'Waitlist',
                confirmedStatus.name,
                pendingStatus.name
              ].includes(student.status)
          )
        default:
          return data?.getRegisteredStudents.map((student) => student)
      }
    }

    return []
  }, [data?.getRegisteredStudents, statusFilter])

  const handleToggleSelected = (id: number) => {
    if (
      selectedParticipant?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipant(
        (prev) => prev?.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipant((prev) => [
        ...(prev ?? []),
        registeredStudents.find(
          (student) => student.user_registrations_id === id
        )
      ])
    }
  }
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipant(registeredStudents)
    } else {
      setSelectedParticipant([])
    }
  }

  const registrationList = useMemo(
    () =>
      data?.getRegisteredStudents.map((regStudent) => {
        const {
          id,
          camp_fees,
          default_jump_price,
          email,
          first_name,
          jump_quantity,
          last_name,
          payments,
          phone_country_code,
          phone_number,
          registrationQuestions,
          registration_code,
          rating,
          slot_duration,
          status,
          totalTime,
          user_registration_options,
          uspa,
          home_dz,
          home_tunnel,
          aad,
          main
        } = regStudent

        // toFixed(2) is used to round to 2 decimal places
        const fixedRemainingBalance =
          regStudent.paymentDetail.outstanding.toFixed(2)

        // Create object to return
        const ignoreList = [
          'name',
          'email',
          'phone',
          'merit_sync',
          'profile_photo',
          'profile_avatar',
          'min_time'
        ]
        const reqVals = {}

        campRequirements
          .filter((req) => !ignoreList.includes(req))
          .forEach((req) => {
            const keys = Object.keys(regStudent)
            if (keys.includes(req) || req === 'wing_load') {
              switch (req) {
                case 'wing_load':
                  reqVals[req] =
                    main?.gear_canopy_size && regStudent.weight
                      ? getWingLoad(main?.gear_canopy_size, regStudent.weight)
                      : ''
                  break
                case 'aad':
                  reqVals[req] = aad?.gear_model.name ?? ''
                  break
                case 'home_dz':
                  reqVals[req] = home_dz?.name ?? ''
                  break
                case 'home_tunnel':
                  reqVals[req] = home_tunnel?.name ?? ''
                  break
                case 'dob':
                  reqVals[req] = regStudent[req]
                    ? moment(regStudent[req]).format('MM/DD/YYYY')
                    : ''
                  break
                case 'location':
                  reqVals[req] = regStudent?.registrationLocation ?? ''
                  break
                default:
                  reqVals[req] = regStudent[req]
                  break
              }
            }
          })

        return {
          id,
          first_name,
          last_name,
          status,
          email,
          phone_country_code,
          phone_number,
          registration_code,
          rating,
          paid_status:
            regStudent.paymentDetail.outstanding > 0 ? 'Pending' : 'Paid',
          totalFees: regStudent.paymentDetail.totalFees / 100,
          totalPaid: regStudent.paymentDetail.totalPaid / 100,
          totalComp: regStudent.paymentDetail.totalComp / 100,
          remainingBalance: fixedRemainingBalance,
          uspa: uspa.uspaStatus,
          ...reqVals,
          ...registrationQuestions?.reduce(
            (acc, { name, user_answer }) => ({
              ...acc,
              [name]: user_answer
            }),
            {}
          )
        }
      }) ?? [],
    [data?.getRegisteredStudents]
  )

  const { isOrganizer, eventOrganizersDataLoading } = useGetEventOrganizers({
    eventId: Number(campId)
  })

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      (currentCoachTeam &&
        currentCoachTeam?.id === Number(campData?.getCampById?.team_id)) ||
      isAdmin
    )
  }, [currentCoachTeam, campData, isAdmin, isOrganizer])

  if (!eventOrganizersDataLoading && !campLoading && !hasAccess()) {
    return <PageNoAccess />
  }

  return (
    <Dimmer
      active={eventOrganizersDataLoading || campLoading}
      loader={eventOrganizersDataLoading || campLoading}
    >
      <div className="container">
        <Grid.Row>
          {!!participantCountByStatus && participantCountByStatus.length > 0 ? (
            <EventRegistrationTotalConfirmed
              participantCountByStatus={participantCountByStatus}
              confirmedOnly={report !== 'list'}
              onClick={(param) =>
                setStatusFilter((prev) => (prev === param ? '' : param))
              }
            />
          ) : (
            <Loading />
          )}

          {report === 'finance' && (
            <>
              <EventRegistrationTotalPayments />
              <EventRegistrationTotalPendingRevenue />
            </>
          )}
        </Grid.Row>
        {/* // save for later implementation
        <FeatureAccessGate feature="feature_alpha">
          <Grid.Row>
            <Text>
              <strong>Loads & Jump Tickets</strong>
            </Text>
          </Grid.Row>
          <Grid.Row className="mt-2">
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 1"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 2"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 3"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3} xs={12} className="px-0 mb-2">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text>
                    {"Day 4"} <small>Aug 11</small>
                  </Text>
                }
                footer={<Text>1 Load | 14 Jump Tickets | $980</Text>}
              />
            </Grid.Col>
          </Grid.Row>
        </FeatureAccessGate>
        */}
        <Grid.Row className="mt-4">
          <Card>
            <Card.Header>
              <Card.Title>
                <strong>
                  <CalendarLink
                    eventName={campData?.getCampById.camp_name}
                    eventId={Number(campId)}
                  />
                </strong>
              </Card.Title>
              <Card.Options>
                <Button
                  icon="list"
                  color="white"
                  className={report === 'list' ? 'text-primary' : 'text-muted'}
                  onClick={() => setReport('list')}
                >
                  LIST
                </Button>
                <Button
                  icon="users"
                  color="white"
                  className={
                    report === 'groups' ? 'text-primary' : 'text-muted'
                  }
                  // onClick={() => history.push(`/event/groups/${campId}`)}
                  onClick={() => setReport('groups')}
                >
                  GROUPS
                </Button>
                <Button
                  icon="award"
                  color="white"
                  className={
                    report === 'controller' ? 'text-primary' : 'text-muted'
                  }
                  onClick={() => setReport('controller')}
                >
                  {isOrg ? 'CONTROLLER' : 'CREDENTIALS'}
                </Button>
                <Button
                  icon="dollar-sign"
                  color="white"
                  className={
                    report === 'finance' ? 'text-primary' : 'text-muted'
                  }
                  onClick={() => setReport('finance')}
                >
                  FINANCE
                </Button>
                <Button.Dropdown
                  key="dropdown"
                  icon="more-vertical"
                  color="white"
                  position="bottom-end"
                  arrow={true}
                  arrowPosition="right"
                  dropdownProps={{
                    className: 'w-25'
                  }}
                >
                  {isCoachSuperAdmin && (
                    <Dropdown.Item
                      icon="upload"
                      color="white"
                      className="cursor-pointer text-muted"
                    >
                      UPLOAD CSV
                    </Dropdown.Item>
                  )}
                  {campHasJumpTickets && (
                    <>
                      <ResetJumpTicketsButton />
                      <Dropdown.ItemDivider />
                    </>
                  )}
                  {!campHasJumpTickets && (
                    <>
                      <AddJumpTicketsButton />
                      <Dropdown.ItemDivider />
                    </>
                  )}
                  {isShirtRequired && (
                    <TShirtDetailsButton registrationList={registrationList} />
                  )}
                  <SendEmailToEventRegisteredUsersButton />
                </Button.Dropdown>
              </Card.Options>
            </Card.Header>
            {report === 'groups' && (
              <>
                {campData?.getCampById.event_type_id ===
                EEventTypes.competition ? (
                  <CompetitionGroupsList
                    campId={campId}
                    campName={campData?.getCampById.camp_name}
                    teamId={campData?.getCampById.team_id}
                    students={campData?.getCampById.registered_students
                      .filter(
                        (s) =>
                          s.status_type &&
                          s.status_type.toLowerCase() === 'confirmed'
                      )
                      .sort((a, b) => a.first_name.localeCompare(b.first_name))}
                  />
                ) : (
                  <EventGroupsList
                    campId={campId}
                    campName={campData?.getCampById.camp_name}
                    teamId={campData?.getCampById.team_id}
                    students={campData?.getCampById.registered_students
                      .filter(
                        (s) =>
                          s.status_type &&
                          s.status_type.toLowerCase() === 'confirmed'
                      )
                      .sort((a, b) => a.first_name.localeCompare(b.first_name))}
                  />
                )}
              </>
            )}
            {report === 'controller' && (
              <EventController registeredStudents={registeredStudents} />
            )}

            {report === 'finance' && (
              <EventFinance
                campData={campData.getCampById}
                registeredStudents={registeredStudents}
              />
            )}
            {report === 'list' && (
              <>
                <Card.Body>
                  <Grid.Row className="p-3">
                    <Grid.Col width={1} className="pt-2">
                      <Form.Label>
                        <input
                          id={`selectAll`}
                          name={'select_all'}
                          type="checkbox"
                          checked={selectAll}
                          onClick={() => {
                            setSelectAll(!selectAll)
                            handleToggleSelectAll(!selectAll)
                          }}
                          onChange={() => {}}
                          className="float-left pb-2"
                        />
                      </Form.Label>
                    </Grid.Col>
                    <Grid.Col width={11} className="text-right p-0">
                      <Button
                        outline={selectedParticipant.length !== 0}
                        icon="circle"
                        color={
                          selectedParticipant.length !== 0
                            ? 'secondary'
                            : 'white'
                        }
                        size={'sm'}
                        className="mr-2"
                        onClick={() => {
                          setIsBulkStatusChangeModalOpen(true)
                        }}
                        disabled={selectedParticipant.length === 0}
                      >
                        STATUS
                      </Button>
                      {(isAdmin || isCoachSuperAdmin) && (
                        <>
                          <Button
                            outline={selectedParticipant.length !== 0}
                            icon="credit-card"
                            color={
                              selectedParticipant.length !== 0
                                ? 'secondary'
                                : 'white'
                            }
                            size={'sm'}
                            className="mr-2"
                            onClick={() => {
                              setIsBulkLogPaymentModalOpen(true)
                            }}
                            disabled={selectedParticipant.length === 0}
                          >
                            PAYMENTS
                          </Button>

                          <Button
                            outline={selectedParticipant.length !== 0}
                            icon="mail"
                            color={
                              selectedParticipant.length !== 0
                                ? 'secondary'
                                : 'white'
                            }
                            size={'sm'}
                            className="mr-2"
                            onClick={() => {
                              setIsBulkEmailModalOpen(true)
                            }}
                            disabled={selectedParticipant.length === 0}
                          >
                            EMAIL
                          </Button>
                        </>
                      )}

                      {!loading && registrationList && !isAdmin && (
                        <Button
                          outline
                          icon="download"
                          color="secondary"
                          size={'sm'}
                        >
                          <CSVLink
                            data={registrationList}
                            filename="registration-list.csv"
                            className="ml-0 text-secondary"
                          >
                            CSV
                          </CSVLink>
                        </Button>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12}>
                      {loading ? (
                        <EventRegistrationListSkeleton />
                      ) : (
                        <Table responsive className="table-vcenter text-nowrap">
                          <Table.Body>
                            {registeredStudents.map(
                              (student: Types.RegisteredStudents) => (
                                <Table.Row key={student.user_registrations_id}>
                                  <EventRegistrationListItem
                                    student={student}
                                    teamId={Number(
                                      campData?.getCampById.team_id
                                    )}
                                    selected={
                                      !!selectedParticipant?.find(
                                        (participant) =>
                                          participant.student_id ===
                                          student.student_id
                                      )
                                    }
                                    handleToggleSelected={handleToggleSelected}
                                  />
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                {selectedParticipant && selectedParticipant.length > 0 && (
                  <>
                    <BulkStatusChangeModal
                      isOpen={isBulkStatusChangeModalOpen}
                      toggle={() => setIsBulkStatusChangeModalOpen(false)}
                      students={selectedParticipant}
                      teamId={Number(campData?.getCampById.team_id)}
                    />
                    <BulkLogPaymentModal
                      isOpen={isBulkLogPaymentModalOpen}
                      toggle={() => setIsBulkLogPaymentModalOpen(false)}
                      students={selectedParticipant}
                      currency={campData?.getCampById.registration_currency}
                      teamId={Number(campData?.getCampById.team_id)}
                    />
                    <BulkEmailModal
                      isOpen={isBulkEmailModalOpen}
                      toggle={() => setIsBulkEmailModalOpen(false)}
                      students={selectedParticipant}
                      teamId={Number(campData?.getCampById.team_id)}
                      campId={Number(campId)}
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid.Row>
      </div>
    </Dimmer>
  )
}

export default EventRegistrationList
